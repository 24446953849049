
import { defineComponent, onMounted, ref, computed } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// @import utils
import { IUserLevel } from '@/core/data/userLevel';
import { IEnumerationFields, IEnumerationTypes } from '@/core/data/enumerationTypes';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
// @import components
import TemplateContentModal from '@/components/modals/forms/TemplateContentModal.vue';

export default defineComponent({
	name: 'Update-Video-Content',
	components: {
		TemplateContentModal
	},
	setup() {
		const route = useRoute();

		const imageModel = ref<any>(null);
		const contentEditor = ref<any>(null);
		const descriptionEditor = ref<any>(null);
		const formRef = ref<null | HTMLFormElement>(null);

		const loading = ref<boolean>(false);
		const userLevel = ref<Array<IUserLevel>>([]);
		const enumerationsType = ref<Array<IEnumerationTypes>>([]);
		
		const pagination = {
			paging: {
				pageNumber: 1,
				pageSize: 100
			},
			filter: {
				searchTerm: ''
			},
			orderBy: {
				orderByField: '',
				orderDesc: false
			}
		};
		const templateListArray = ref(Array<any>());
		const enumerationListArray = ref(Array<any>());

		templateListArray.value = [];
		enumerationListArray.value = [];

		const isEdit = route.params.id;
		
		var sample: {
			id: string,
			includeEvents: boolean,

			title: string,
			displayTitle: string,
			buttonUrl: string,
			buttonLabel: string,
			publicationDate: string,
			relatedContentType: string,
			displayItemsAmount: number,
			templateId: string,
			published: boolean,
			public: boolean,
			userLevels: Array<number>,
			display: boolean,
			nested: boolean,
			metaFieldIds: Array<number>,
			filterType: string,
			created: Date,
			updated: Date,
		} = {
			id: '',
			includeEvents: false,
			title: '',
			displayTitle: '',
			buttonUrl: '',
			buttonLabel: '',
			publicationDate: '',
			relatedContentType: '',
			displayItemsAmount: 1,
			templateId: "",
			published: false,
			public: true,
			userLevels: [],
			display: true,
			nested: false,
			metaFieldIds: [],
			filterType: "",
			created: new Date(),
			updated: new Date(),
		}
		const formData = ref(sample);

		const rules = ref({
			title: [
				{
					required: true,
					message: 'Title is required',
					trigger: 'change'
				}
			],
			displayTitle: [
				{
					required: true,
					message: 'Display Title is required',
					trigger: 'change'
				}
			],
			displayItemsAmount: [
				{
					required: true,
					message: 'number of items is required'
				}
			],
			relatedContentType:[
				{
					required: true,
					message: 'Content type is required',
					trigger: 'change'
				}
			]
		});

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} Overview`, [
				'Overviews'
			]);
			if (isEdit) {
				getOverviewById();
			}
			getUserLevels();
		});

		const getEnumerationsType = async () => {
			var url = 'TabFields/' + formData.value.relatedContentType.toLocaleLowerCase();
			if(formData.value.relatedContentType === "TemplateContent")
			{
				await getEnumerationList()

				if(formData.value.templateId != "")
				{
					url += "/" + formData.value.templateId;
				}
				else
				{
					return;
				}
			}

			await ApiService.get(url)
				.then(({ data }) => {
					// console.log('enumerationsType response: ', data);
					enumerationsType.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				})
				.finally(() => {
					if(formData.value.relatedContentType != "TemplateContent")
					{
						return;
					}

					var template = templateListArray.value.find(t => t.templateId === formData.value.templateId)
					if(!template)
					{
						return;
					}

					var templateEnum = enumerationListArray.value.find(enumerable => enumerable.id === template.enumerationId)
					if(!templateEnum)
					{
						return;
					}
					var ent = <IEnumerationFields>{
						id: -1,
						fieldType: "TemplateEnum",
						displayName: templateEnum.enumName,
						jsonProperty: "TemplateEnum",
						enemerationValues: [],
						enumReference: ""
					};

					var metaTab = enumerationsType.value.find(i => i.jsonProperty === 'meta');
					if(metaTab)
					{
						metaTab.fields.unshift(ent)
					}
					else
					{
						enumerationsType.value = [<IEnumerationTypes>{
							jsonProperty: 'meta',
							fields: [ent]
						}]
					}

				});
			

		};

		const getOverviewById = () => {
			console.log("Start fetch");
			ApiService.get(`Overview/${route.params.id}`)
				.then(({ data }) => {
					formData.value = {
						...formData.value,
						id: data.id,
						title: data.title,
						displayTitle: data.displayTitle,
						buttonUrl: data.buttonUrl,
						buttonLabel: data.buttonLabel,
						publicationDate: data.publicationDate,
						created: data.created,
						updated: data.updated,
						relatedContentType: data.relatedContent.type,
						templateId: data.relatedContent.templateId,
						displayItemsAmount: data.relatedContent.displayItemsAmount,
						published: data.accessInfo.published,
						public: data.accessInfo.public,
						display: data.filter.display,
						nested: data.filter.nested,
						filterType: data.filter.type,
						includeEvents: data.includeEvents,
						metaFieldIds: data.filter.fields.map(({ id }) => id),
						userLevels: data.accessInfo.userLevels? data.accessInfo.userLevels.split(',') : []
					};
					getEnumerationsType();
					if(formData.value.templateId)
					{
						getTemplateList();
					}
					console.log(formData);
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getUserLevels = () => {
			ApiService.get('UserLevel')
				.then(({ data }) => {
					// console.log('UserLevel response: ', data);
					userLevel.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};
		const getTemplateList = async () => {
			if(templateListArray.value.length > 0)
			{
				return;
			}
			// fetch contents
			const contentListArrayResult = await ApiService.post(
				`Template/list/Page`,
				pagination
			);

			templateListArray.value = templateListArray.value.concat(contentListArrayResult.data);

			// fetch components
			const componentListAResult = await ApiService.post(
				`Template/list/Component`,
				pagination
			);
			templateListArray.value = templateListArray.value.concat(componentListAResult.data)
		};

		const getEnumerationList = async () => {
				if(enumerationListArray.value.length != 0)
				{
					return;
				}

				await ApiService.get('Enumeration')
					.then(({ data }) => {
						// console.log('enumerationsType response: ', data);
						enumerationListArray.value = data;
					})
					.catch(({ response }) => {
						console.log('response', response);
					});
				
		}
		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					console.log("start validating");
					loading.value = true;
					const data: any = {
						...formData.value,
						userLevels: formData.value.userLevels.length === 0? null : formData.value.userLevels.toString(),
						filterType: formData.value.filterType == ''? null: formData.value.filterType,
						metaFieldIds: formData.value.metaFieldIds.filter(x => x != -1),
						includeTemplateEnumeration: formData.value.metaFieldIds.includes(-1)
					};

					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(
							`Overview/${route.params.id}`,
							data
						);
					} else {
						delete data['id'];
						serviceType = ApiService.post('Overview', data);
					}

					serviceType
						.then(({ data }) => {
							loading.value = false;
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (isEdit) {
									formData.value = {
										...formData.value,
										...data,
										userLevels: data.accessInfo.userLevels? data.accessInfo.userLevels.split(',') : []
									};
								} else {
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();
								}
							});
						})
						.catch(({ response }) => {
							console.log('create video content error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		const OnRelatedContentChange = (event) =>
		{
			console.log("related content changed")
			formData.value.metaFieldIds = [];
			formData.value.templateId = "";
			formData.value.includeEvents = false;
			enumerationsType.value = [];

			if(formData.value.relatedContentType === "TemplateContent")
			{
				getTemplateList();
			}
			getEnumerationsType();
		}

		const OnTemplateIdChange = (event) =>
		{
			formData.value.metaFieldIds = [];
			enumerationsType.value = [];

			getEnumerationsType();
		}

		return {
			rules,
			moment,
			isEdit,
			getEnumerationsType,
			OnRelatedContentChange,
			OnTemplateIdChange,
			submit,
			templateListArray,
			enumerationsType,
			formRef,
			loading,
			formData,
			userLevel,
			imageModel,
			contentEditor,
			descriptionEditor,
		};
	}
});
